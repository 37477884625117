<template>
  <div class="tryuse" :style="styleObject">
    <Introduce :moduleId="1" class="try_introduce"/>
    <div class="pcbox">
      <p class="pc_title">免费试用</p>
      <div class="try_box">
        <p class="try_title">申请免费试用</p>
        <p class="try_desc">请完善信息，我们会尽快联系您，为您提供试用名额</p>
        <dj-input ref="nameinput" class="try_name" v-model="data.name.value" :regulars="data.name.regulars"
                  :rules="data.name.rules" :max="data.name.max" placeholder="请输入您的姓名" @blur="inputBlur"></dj-input>
        <dj-input ref="telinput" class="try_tel" v-model="data.tel.value" :regulars="data.tel.regulars"
                  :rules="data.tel.rules" :max="data.tel.max" placeholder="请输入您的手机号码" @blur="telBlur"></dj-input>
        <div class="try_code">
          <dj-input class="code_input" ref="codeinput" v-model="data.code.value" :regulars="data.code.regulars"
                    :rules="data.code.rules" :max="data.code.max" placeholder="请输入手机短信验证码" @blur="inputBlur"></dj-input>
          <dj-button type="dark" class="codebtn" :class="{disabled:(isCode||codeDisabled)}" @click.native="getCode">
            {{ isCode ? `${count}s后重新获取` : '获取验证码' }}
          </dj-button>
        </div>
        <dj-input class="try_business" ref="businessinput" v-model="data.business.value"
                  :regulars="data.business.regulars" :rules="data.business.rules" :max="data.business.max"
                  placeholder="请输入完整的企业名称"></dj-input>
        <p class="try_sub">
          <dj-button type="dark" class="submitbtn" :class="{disabled:!isButton}" @click.native="submit">立即提交</dj-button>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {sendPhoneCode, applyUse} from "@/api/product.js";
import {getPictures} from "@/api/common/index";
import $ from 'jquery';
import metaInfoMixins from "@/utils/mixin/metaInfoMixins";
import utils from "@/utils/utils";

var telValidate = (value) => {
  if (!(/^1[3456789]\d{9}$/.test(value))) {
    return false;
  } else {
    return true;
  }
};
export default {
  mixins: [metaInfoMixins],
  data() {
    return {
      data: {
        name: {
          value: "",
          max: 20,
          regulars: [],
          rules: [
            {required: true, message: '请输入姓名'}
          ]
        },
        tel: {
          value: "",
          max: 11,
          regulars: [/[^\d]/g],
          rules: [
            {validator: telValidate, message: '请输入正确的手机号'},
            {required: true, message: '请输入手机号'}
          ]
        },
        code: {
          value: "",
          max: 6,
          regulars: [/[^\d]/g],
          rules: [
            {required: true, message: '请输入短信验证码'}
          ]
        },
        business: {
          value: "",
          max: 30,
          regulars: [],
          rules: []
        },
      },
      count: 60,
      isCode: false,
      codeDisabled: false,
      isButton: true,
      styleObject: {},
      picture: ""
    };
  },
  created() {
    this.getPicturesFun();
  },
  methods: {
    getPicturesFun() {
      let params = {
        productId: this.$store.getters.getProductBasicInfo.productId,
        moduleType: 1
      };
      getPictures(params).then((res) => {
        if (res.success) {
          for (let i of res.data.list) {
            if (i.pictureType === 2) {
              this.picture = i.picture;
              this.setBgUrl();
              return;
            }
          }
        }
      });
    },
    setBgUrl() {
      let w = $(window).width();
      if (w > 960) {
        this.styleObject = {
          backgroundImage: `url(${utils.setOssUrl(this.picture)})`
        };
      } else {
        this.styleObject = {};
      }
      window.onresize = () => {
        let w = $(window).width();
        if (w > 960) {
          this.styleObject = {
            backgroundImage: `url(${utils.setOssUrl(this.picture)})`
          };
        } else {
          this.styleObject = {};
        }
      };
    },
    telBlur() {
      let isTel = this.$refs.telinput.checkRules();
      this.codeDisabled = !isTel;
      this.inputBlur();
    },
    inputBlur() {
      let isName = this.$refs.nameinput.getRulesValue();
      let isTel = this.$refs.telinput.getRulesValue();
      let isCode = this.$refs.codeinput.getRulesValue();
      if (isName && isTel && isCode) {
        this.isButton = true;
      }
    },
    countDown() {
      this.isCode = true;
      let timer = setInterval(() => {
        this.count--;
        if (this.count === 0) {
          clearInterval(timer);
          this.isCode = false;
          this.count = 60;
        }
      }, 1000);
    },
    getCode() {
      let isTel = this.$refs.telinput.checkRules();
      if (!isTel) {
        this.codeDisabled = true;
      }
      if (isTel && !this.isCode) {
        let params = {
          phone: this.data.tel.value,
          type: "productTry"
        };
        sendPhoneCode(params).then((res) => {
          if (res.success) {
            this.countDown();
          } else {
            this.$message(res.msg);
          }
        });
      }
    },
    submit() {
      if(this.isButton){
        // 免费试用立即提交神策埋点
        this.$sensors.officialSite_SubmitFreeUse();
      }
      let isName = this.$refs.nameinput.checkRules();
      let isTel = this.$refs.telinput.checkRules();
      let isCode = this.$refs.codeinput.checkRules();
      let isBusiness = this.$refs.businessinput.checkRules();
      if (isName && isTel && isCode && isBusiness) {
        this.isButton = true;
        // 发送请求
        let params = {
          userName: this.data.name.value,
          phone: this.data.tel.value,
          company: this.data.business.value,
          phoneCode: this.data.code.value
        };
        applyUse(params).then((res) => {
          // 免费试用提交详情神策埋点
          let obj = {
            officialSite_e_freeUseDetailsSource: this.$route.query.source,
            officialSite_e_productName: this.$store.getters.getProductBasicInfo.productName,
            officialSite_e_intentionType: "申请试用",
            officialSite_e_isSuccess: res.success
          };
          this.$sensors.officialSite_FreeUseDetails(obj);
          if (res.success) {
            this.$message("申请已提交成功，稍后会有工作人员与您联系！", () => {
              this.$router.push({
                path: '/'
              });
            });
          } else {
            this.$message(res.msg);
          }
        });
      } else {
        this.isButton = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.tryuse {
  .try_introduce {
  }
  .pc_title {
    display: none;
  }
  .try_box {
    display: flex;
    flex-direction: column;
    padding: 0 60px;
    .try_title {
      font-size: 36px;
      color: #333333;
      line-height: 50px;
      margin-top: 60px;
      text-align: center;
      font-weight: bold;
    }
    .try_desc {
      font-size: 24px;
      color: #333333;
      line-height: 33px;
      margin-top: 16px;
      text-align: center;
    }
    input {
      width: 100%;
      height: 80px;
      background: #FFFFFF;
      border: 1px solid #CCCCCC;
      padding: 0 24px;
      box-sizing: border-box;
      font-size: 26px;
      position: relative;
    }
    .try_input {
      position: relative;
      p {
        display: none;
        font-size: 12px;
        color: #DB0010;
        position: absolute;
        top: 80px;
        left: 0;
      }
    }
    .error_input {
      input {
        border: 1px solid #DB0010;
      }
      p {
        display: block;
      }
    }
    .try_name {
      width: 100%;
      margin-top: 40px;
    }
    .try_tel {
      width: 100%;
      margin-top: 40px;
    }
    .try_code {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 40px;
      .code_input {
        width: 406px;
      }
      .codebtn {
        /deep/ a {
          width: 200px;
          height: 56px;
          font-size: 19px;
        }
      }
      .disabled {
        /deep/a{
          background: #999999;
          cursor: default;
        }
      }
    }
    .try_business {
      width: 100%;
      margin-top: 40px;
    }
    .try_sub {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 60px 0;
      .submitbtn {
        /deep/ a {
          width: 310px;
          height: 73px;
          font-size: 24px;
        }
      }
      .disabled {
        /deep/a{
          background: #999999;
          cursor: default;
        }
      }
    }
  }
}
@media (min-width: 751px) and (max-width: 960px) {
  .tryuse {
    .try_introduce {
    }
    .pc_title {
      display: none;
    }
    .try_box {
      display: flex;
      flex-direction: column;
      padding: 0 132px;
      .try_title {
        font-size: 28px;
        color: #333333;
        line-height: 40px;
        margin-top: 60px;
        text-align: center;
        font-weight: bold;
      }
      .try_desc {
        font-size: 18px;
        color: #333333;
        line-height: 25px;
        margin-top: 9px;
        text-align: center;
      }
      input {
        width: 100%;
        height: 48px;
        background: #FFFFFF;
        border: 1px solid #CCCCCC;
        padding: 0 24px;
        box-sizing: border-box;
        font-size: 14px;
        position: relative;
      }
      .try_input {
        position: relative;
        p {
          display: none;
          font-size: 12px;
          color: #DB0010;
          position: absolute;
          top: 80px;
          left: 0;
        }
      }
      .error_input {
        input {
          border: 1px solid #DB0010;
        }
        p {
          display: block;
        }
      }
      .try_name {
        width: 100%;
        margin-top: 24px;
      }
      .try_tel {
        width: 100%;
        margin-top: 24px;
      }
      .try_code {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 24px;
        .code_input {
          width: 552px;
        }
        .codebtn {
          /deep/ a {
            width: 120px;
            height: 40px;
            font-size: 13px;
          }
        }
        .disabled {
          /deep/a{
            background: #999999;
            cursor: default;
          }
        }
      }
      .try_business {
        width: 100%;
        margin-top: 24px;
      }
      .try_sub {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 40px 0 60px 0;
        .submitbtn {
          /deep/ a {
            width: 223px;
            height: 52px;
            font-size: 18px;
          }
        }
        .disabled {
          background: #999999;
          cursor: default;
        }
      }
    }
  }
}
@media (min-width: 961px) {
  .tryuse {
    background-image: url("../../assets/images/bg_default.jpg");
    background-position: center;
    background-size: cover;
    height: 630px;
    width: 100%;
    overflow: hidden;
    .try_introduce {
      display: none;
    }
    .pcbox {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
    }
    .pc_title {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 48px;
      color: #FFFFFF;
      line-height: 67px;
      margin-top: 36px;
    }
    .try_box {
      margin-top: 36px;
      display: flex;
      flex-direction: column;
      padding: 0 32px;
      width: 486px;
      height: 558px;
      background: #F5F7FA;
      .try_title {
        font-size: 20px;
        line-height: 28px;
        margin-top: 40px;
      }
      .try_desc {
        font-size: 14px;
        line-height: 20px;
      }
      input {
        width: 100%;
        height: 48px;
        background: #FFFFFF;
        border: 1px solid #CCCCCC;
        padding: 0 13px;
        box-sizing: border-box;
        font-size: 14px;
        position: relative;
      }
      .try_input {
        position: relative;
        p {
          display: none;
          font-size: 12px;
          color: #DB0010;
          position: absolute;
          top: 80px;
          left: 0;
        }
      }
      .try_name {
        width: 100%;
        margin-top: 34px;
      }
      .try_tel {
        width: 100%;
        margin-top: 30px;
      }
      .try_code {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;
        .code_input {
          width: 306px;
        }
        .codebtn {
          /deep/ a {
            width: 100px;
            height: 40px;
            font-size: 13px;
          }
        }
      }
      .try_business {
        width: 100%;
        margin-top: 30px;
      }
      .try_sub {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 44px 0;
        .submitbtn {
          /deep/ a {
            width: 228px;
            height: 50px;
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
